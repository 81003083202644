// Import images
import WebImage2 from '../images/mundiapp.png';
import MobileImage2 from '../images/image (4).png';
import Image1 from "../images/ui-project-1.jpg";
import Image2 from "../images/mundiapp1.png";
import Image3 from "../images/mundiapp4.png";
import {FiFacebook, FiInstagram, FiLinkedin, FiTwitter, FiYoutube} from "react-icons/fi";
import Image4 from "../images/image.png";
import Image5 from "../images/image (3).png";
import Image6 from "../images/image (2).png";

export const projectsData = [
    {
        id: 1,
        title: 'MundiApp',
        category: 'Web Application - E-commerce',
        img: WebImage2,
        ProjectHeader: {
            title: 'Mundi-App',
            publishDate: 'Oct 2022',
            tags: 'Fullstack Web Application',
        },
        ProjectImages: [
            {
                id: 1,
                title: 'Kabul Project Management UI',
                img: WebImage2,
            },
            {
                id: 2,
                title: 'Kabul Project Management UI',
                img: Image2,
            },
            {
                id: 3,
                title: 'Kabul Project Management UI',
                img: Image3,
            },
        ],
        ProjectInfo: {
            ClientHeading: 'Sobre el Cliente',
            CompanyInfo: [
                {
                    id: 1,
                    title: 'Nombre',
                    details: 'HENRY',
                },
                {
                    id: 2,
                    title: 'Servicios',
                    details: 'Bootcamp Desarrollo web y Data Science',
                },
                {
                    id: 3,
                    title: 'Website',
                    details: 'https://soyhenry.com',
                },

            ],
            ObjectivesHeading: 'Objetivo',
            ObjectivesDetails:
                'Crear desde 0 una aplicacion web, en este caso un E-commerce, que permita al usuario' +
                ' logearse, y hacer compras ' +
                'con la implementacion de una billetera virtual. ' +
                'Amigable para el usuario y eficiente para el administrador. ',
            Technologies: [
                {
                    title: 'Tecnologias',
                    techs: [
                        'React',
                        'Nodejs',
                        'Redux',
                        'Mongodb',
                        'TailwindCSS',
                        'Expressjs',
                        'mongoose',
                        'Auth0',
                        'Mercado Pago',
                        'entre otras.'
                    ],
                },
            ],
            Deploy: [
                {
                    title: 'Deploy',
                    details: 'https://mundi-app.tk'
                }
            ],
            ProjectDetailsHeading: 'Desafio',
            ProjectDetails: [
                {
                    id: 1,
                    details:
                        'Disenar y desarrollar un E-commerce de articulos deportivos, merch y entradas para el mundial de Qatar 2022, que permite al usuario iniciar sesion como usuario de la tienda o de Google, y comprar productos a traves de la billetera de Mercado Pago.'
                },
                {
                    id: 2,
                    details:
                        'App desarrollada con React-Redux + Tailwind en el cliente y con ExpressJs + mongodb en el servidor.'
                },
                {
                    id: 3,
                    details:
                        'Forme parte del equipo de desarrollo como fullstack developer, aunque mi aporte mas significativo fue del lado del cliente, con la implementacion de redux, funcionalidades para la busqueda, filtrado y ordenamiento de productos, logica del carrito de compras, dashboard del usuario y deploy entre otras.'
                },

            ],
            SocialSharing: [],
        },
    },






    {
        id: 2,
        title: 'Countries - PI HENRY (individual project)',
        category: 'Web Application',
        img: MobileImage2,


        ProjectHeader: {
            title: 'Countries - PI HENRY (individual project)',
            publishDate: 'Septiembre 2022',
            tags: 'Fullstack Web Application',
        },
        ProjectImages: [
            {
                id: 1,
                title: '',
                img: Image4,
            },
            {
                id: 2,
                title: '',
                img: Image5,
            },
            {
                id: 3,
                title: '',
                img: Image6,
            },
        ],
        ProjectInfo: {
            ClientHeading: 'Sobre el Cliente',
            CompanyInfo: [
                {
                    id: 1,
                    title: 'Nombre',
                    details: 'HENRY',
                },
                {
                    id: 2,
                    title: 'Servicios',
                    details: 'Bootcamp Desarrollo web y Data Science',
                },
                {
                    id: 3,
                    title: 'Website',
                    details: 'https://soyhenry.com',
                },

            ],
            ObjectivesHeading: 'Objetivo',
            ObjectivesDetails:
                'Crear una aplicacion de paises con su informacion basica traida desde una API y con ' +
                'informacion turistica de cada pais. ' +
                'Se podra ordenar, filtrar, realizar busquedas y agregar nuevas actividades turisticas.',
            Technologies: [
                {
                    title: 'Tecnologias',
                    techs: [
                        'React',
                        'Nodejs',
                        'Redux',
                        'PostgreSQL',
                        'Expressjs',
                        'Sequelize.',
                    ],
                },
            ],
            Deploy: [
                {
                    title: 'Deploy',
                    details: 'https://countries-nicoese.tk/'
                }
            ],
            ProjectDetailsHeading: 'Desafio',
            ProjectDetails: [
                {
                    id: 1,
                    details:
                    'Disenar y desarrollar una App de paises que incluya busqueda, filtrado, ordenamiento, informacion turistica del pais, y creacion.\n' +
                        'App desarrollada con React-Redux y css vanilla en el cliente y con ExpressJs y postgres sequelize en el servidor.\n'
                },


            ],
            SocialSharing: [],
        },



    },

];
