import Button from '../reusable/Button';
import {ErrorMessage, Field, Form, Formik} from "formik";
import emailjs from "@emailjs/browser";
import {useState} from "react";

const ContactForm = () => {

	let [state, setState] = useState({
		status: ''
	});

	const sendEmail = async (e) => {

		const form = document.getElementsByTagName('form').item(0)

		emailjs.sendForm('service_wspx58t',
			'template_p2xk3z9',
			form,
			'fDlarduQv6r5O4-Jo')
			.then(result => {

				setState({
					...state,
					status: result.status
				})
			})
			.catch(error => {

				setState({
					...state,
					status: error.status
				})
			})

		setTimeout((e) => {
			setState({
				...state,
				status: ''
			})
		}, 8000)

	}



	return (
		<div className="w-full lg:w-1/2">
			<div className="leading-loose">
				<Formik
					initialValues={{
						name: '',
						email: '',
						subject: '',
						message: ''
					}}

					validate = {(values) => {
						let errors = {};


						if (!values.name) {
							errors.name = 'Por favor ingresa un nombre'
						} else if (!/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(values.name)) {
							errors.name = 'El nombre solo puede contener letras y espacios'
						}
						if (!values.email) {
							errors.email = 'Por favor ingresa un email'
						} else if (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(values.email)) {
							errors.email = 'El correo solo puede contener letras, numeros, puntos, guiones y guion bajo'
						}
						if (!values.subject) {
							errors.subject = 'Por favor ingresa un titulo para el mensaje'
						}
						if (values.message.length > 1000 || values.message.length < 10) {
						    errors.message = 'El mensaje no puede tener menos de 10 caracteres ni mas de 1000.'
						}


						return errors;
					}}

					onSubmit={async (e, {resetForm}) => {
						resetForm();
						console.log(e)
						await sendEmail(e)
					}}
					className="max-w-xl m-4 text-left"
				>

					{({errors}) => (
						<Form
					className="max-w-xl m-4 p-6 sm:p-10 bg-secondary-light dark:bg-secondary-dark rounded-xl shadow-xl
					text-left"
				>
					<p className="font-general-medium text-primary-dark dark:text-primary-light text-2xl mb-8">
						Formulario de contacto
					</p>
					<div className="font-general-regular">
						<label
							className="block text-lg text-primary-dark dark:text-primary-light mb-2"
							htmlFor="name"
						>
							Nombre Completo
						</label>
						<Field
							className="w-full px-5 py-2 border border-gray-300 dark:border-primary-dark
							border-opacity-50 text-primary-dark dark:text-secondary-light bg-ternary-light
							dark:bg-ternary-dark rounded-md shadow-sm text-md"
							id="name"
							name="name"
							type="text"
							required
							placeholder="Tu Nombre"
							aria-label="Name"
						/>
						<ErrorMessage name={'name'} component={() => (
							<div className={'error mt-4 mx-3 dark:border-secondary-dark rounded-md text-md ' +
								'text-primary-dark dark:text-ternary-light'}>{errors.name}</div>)}/>
					</div>
					<div className="mt-6">
						<label
							className="block text-lg text-primary-dark dark:text-primary-light mb-2"
							htmlFor="email"
						>
							Email
						</label>
						<Field
							className="w-full px-5 py-2 border border-gray-300 dark:border-primary-dark border-opacity-50 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md"
							id="email"
							name="email"
							type="text"
							required
							placeholder="Tu Email"
							aria-label="Email"
						/>
						<ErrorMessage name={'email'} component={() => (
							<div
								className={'error mt-4 mx-3 dark:border-secondary-dark rounded-md ' +
									'text-md text-primary-dark dark:text-ternary-light'}>{errors.email}
							</div>)}/>
					</div>
					<div className="mt-6">
						<label
							className="block text-lg text-primary-dark dark:text-primary-light mb-2"
							htmlFor="subject"
						>
							Asunto
						</label>
						<Field
							className="w-full px-5 py-2 border border-gray-300 dark:border-primary-dark border-opacity-50 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md"
							id="subject"
							name="subject"
							type="text"
							required
							placeholder="Titulo"
							aria-label="Subject"
						/>
						<ErrorMessage name={'subject'} component={() => (
							<div
								className={'error mt-4 mx-3 dark:border-secondary-dark rounded-md ' +
									'text-md text-primary-dark dark:text-ternary-light'}>{errors.subject}
							</div>)}/>
					</div>

					<div className="mt-6">
						<label
							className="block text-lg text-primary-dark dark:text-primary-light mb-2"
							htmlFor="message"
						>
							Mensaje
						</label>
						<Field
							as={'textarea'}
							className="w-full px-5 py-2 border border-gray-300 dark:border-primary-dark border-opacity-50 text-primary-dark dark:text-secondary-light bg-ternary-light dark:bg-ternary-dark rounded-md shadow-sm text-md"
							id="message"
							name="message"
							cols="14"
							rows="6"
							aria-label="Message"
						></Field>
						<ErrorMessage name={'message'} component={() => (
							<div
								className={'error mt-4 mx-3 dark:border-secondary-dark rounded-md ' +
									'text-md text-primary-dark dark:text-ternary-light'}>{errors.message}
							</div>)}/>
					</div>
					<div className="font-general-medium w-40 px-4 py-2.5 text-white text-center font-medium tracking-wider bg-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 rounded-lg mt-6 duration-500">
						<Button
							title="Enviar"
							type="submit"
							aria-label="Send Message"
						/>
					</div>
							{state.status === 200 && <div
								className="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg
                                            dark:bg-green-200 dark:text-green-800
                                            dark:border-secondary-dark rounded-md text-md text-primary-dark
                                            dark:text-ternary-light"
								role="alert">
								<span className="font-medium">Mensaje enviado con exito!</span>
							</div>}
				</Form>
					)}
				</Formik>


			</div>
		</div>
	);
};

export default ContactForm;
