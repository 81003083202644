import { useContext } from 'react';
import SingleProjectContext from '../../context/SingleProjectContext';
import {projectsData} from "../../data/projects";
import {useNavigate, useParams} from "react-router-dom";

const ProjectGallery = () => {
	const params = useParams()
	const navigate = useNavigate()
	const singleProjectData  = projectsData;


	return (
		<div className="grid grid-cols-1 sm:grid-cols-3 sm:gap-10 mt-12">
			{ params.id < 1 || params.id > 2 || Number.isNaN(Number(params.id)) ? navigate('/') : singleProjectData[params.id-1].ProjectImages.map((project) => {
				return (
					<div className="mb-10 sm:mb-0" key={project.id}>
						<img
							src={project.img}
							className="rounded-xl cursor-pointer shadow-lg sm:shadow-none"
							alt={project.title}
							key={project.id}
						/>
					</div>
				);
			})}
		</div>
	);
};

export default ProjectGallery;
