import profileImage from '../../images/profile.jpeg';

const AboutMeBio = () => {

    return (
        <div className="block sm:flex sm:gap-10 mt-10 sm:mt-20">
            <div className="w-full sm:w-1/4 mb-7 sm:mb-0">
                <img src={profileImage} className="rounded-lg w-96" alt=""/>
            </div>

            <div className="font-general-regular w-full sm:w-3/4 text-left">
                <p
                    className="mb-4 text-ternary-dark dark:text-ternary-light text-lg"
                >
                    {'Full Stack Developer con formacion en desarrollo web.\n' +
                        'Experiencia trabajando en React, Spring boot, Nodejs, Redux, SQL y mongodb\n' +
                        'entre otras tecnologias.\n' +
                        'He formado parte en mas de un equipo de trabajo para la creacion de \n' +
                        'E-commerces y aplicaciones tipo CRM.\n' +
                        'Me destaco en pensamiento logico, resolucion de problemas y trabajo en equipo.'}
                </p>

                <h3 className={'text-ternary-dark dark:text-ternary-light text-lg'}>Lenguajes y frameworks:</h3>


                    <p className={'flex py-5'}><a href="https://www.w3schools.com/css/" target="_blank" rel="noreferrer"> <img
                        src="https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original-wordmark.svg"
                        alt="css3" width="40" height="40"/> </a> <a href="https://www.docker.com/" target="_blank"
                                                                    rel="noreferrer"> <img
                        src="https://raw.githubusercontent.com/devicons/devicon/master/icons/docker/docker-original-wordmark.svg"
                        alt="docker" width="40" height="40"/> </a> <a href="https://expressjs.com" target="_blank"
                                                                      rel="noreferrer"> <img
                        src="https://raw.githubusercontent.com/devicons/devicon/master/icons/express/express-original-wordmark.svg"
                        alt="express" width="40" height="40"/> </a> <a href="https://git-scm.com/" target="_blank"
                                                                       rel="noreferrer"> <img
                        src="https://www.vectorlogo.zone/logos/git-scm/git-scm-icon.svg" alt="git" width="40" height="40"/>
                    </a> <a href="https://www.w3.org/html/" target="_blank" rel="noreferrer"> <img
                        src="https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original-wordmark.svg"
                        alt="html5" width="40" height="40"/> </a> <a href="https://www.java.com" target="_blank"
                                                                     rel="noreferrer"> <img
                        src="https://raw.githubusercontent.com/devicons/devicon/master/icons/java/java-original.svg"
                        alt="java" width="40" height="40"/> </a> <a
                        href="https://developer.mozilla.org/en-US/docs/Web/JavaScript" target="_blank" rel="noreferrer">
                        <img
                            src="https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg"
                            alt="javascript" width="40" height="40"/> </a> <a href="https://www.linux.org/" target="_blank"
                                                                              rel="noreferrer"> <img
                        src="https://raw.githubusercontent.com/devicons/devicon/master/icons/linux/linux-original.svg"
                        alt="linux" width="40" height="40"/> </a> <a href="https://www.mongodb.com/" target="_blank"
                                                                     rel="noreferrer"> <img
                        src="https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original-wordmark.svg"
                        alt="mongodb" width="40" height="40"/> </a> <a href="https://www.mysql.com/" target="_blank"
                                                                       rel="noreferrer"> <img
                        src="https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original-wordmark.svg"
                        alt="mysql" width="40" height="40"/> </a> <a href="https://nodejs.org" target="_blank"
                                                                     rel="noreferrer"> <img
                        src="https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original-wordmark.svg"
                        alt="nodejs" width="40" height="40"/> </a> <a href="https://www.postgresql.org" target="_blank"
                                                                      rel="noreferrer"> <img
                        src="https://raw.githubusercontent.com/devicons/devicon/master/icons/postgresql/postgresql-original-wordmark.svg"
                        alt="postgresql" width="40" height="40"/> </a> <a href="https://postman.com" target="_blank"
                                                                          rel="noreferrer"> <img
                        src="https://www.vectorlogo.zone/logos/getpostman/getpostman-icon.svg" alt="postman" width="40"
                        height="40"/> </a> <a href="https://reactjs.org/" target="_blank" rel="noreferrer"> <img
                        src="https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original-wordmark.svg"
                        alt="react" width="40" height="40"/> </a> <a href="https://redux.js.org" target="_blank"
                                                                     rel="noreferrer"> <img
                        src="https://raw.githubusercontent.com/devicons/devicon/master/icons/redux/redux-original.svg"
                        alt="redux" width="40" height="40"/> </a> <a href="https://www.selenium.dev" target="_blank"
                                                                     rel="noreferrer"> <img
                        src="https://raw.githubusercontent.com/detain/svg-logos/780f25886640cef088af994181646db2f6b1a3f8/svg/selenium-logo.svg"
                        alt="selenium" width="40" height="40"/> </a> <a href="https://spring.io/" target="_blank"
                                                                        rel="noreferrer"> <img
                        src="https://www.vectorlogo.zone/logos/springio/springio-icon.svg" alt="spring" width="40"
                        height="40"/> </a> <a href="https://tailwindcss.com/" target="_blank" rel="noreferrer"> <img
                        src="https://www.vectorlogo.zone/logos/tailwindcss/tailwindcss-icon.svg" alt="tailwind" width="40"
                        height="40"/> </a> <a href="https://www.typescriptlang.org/" target="_blank" rel="noreferrer"> <img
                        src="https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-original.svg"
                        alt="typescript" width="40" height="40"/> </a></p>








            </div>
        </div>
    );
};

export default AboutMeBio;
