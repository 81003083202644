import {motion} from 'framer-motion';
import {FiX} from 'react-icons/fi';
import Button from './reusable/Button';
import {ErrorMessage, Field, Form, Formik} from "formik";
import emailjs from '@emailjs/browser'
import {useState} from "react";

const selectOptions = [
    'Selecciona un tipo de app',
    'Fullstack app',
    'Frontend',
    'Backend',
];

const HireMeModal = ({onClose, onRequest}) => {

        let [state, setState] = useState({
            status: ''
        });

        const sendEmail = async (e) => {

            const form = document.getElementsByTagName('form').item(0)

            emailjs.sendForm('service_wspx58t',
                'template_p2xk3z9',
                form,
                'fDlarduQv6r5O4-Jo')
                .then(result => {

                    setState({
                        ...state,
                        status: result.status
                    })
                })
                .catch(error => {

                    setState({
                        ...state,
                        status: error.status
                    })
                })

            setTimeout((e) => {
                setState({
                    ...state,
                    status: ''
                })
            }, 8000)

        }


        let div = <>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                className="font-general-medium fixed inset-0 z-30 transition-all duration-500"
            >
                {/* Modal Backdrop */}
                <div className="bg-filter bg-black bg-opacity-50 fixed inset-0 w-full h-full z-20"></div>

                {/* Modal Content */}
                <main className="flex flex-col items-center justify-center h-full w-full">
                    <div className="modal-wrapper flex items-center z-30">
                        <div
                            className="modal max-w-md mx-5 xl:max-w-xl lg:max-w-xl md:max-w-xl bg-secondary-light
                    dark:bg-primary-dark max-h-screen shadow-lg flex-row rounded-lg relative">
                            <div
                                className="modal-header flex justify-between gap-10 p-5 border-b border-ternary-light
                        dark:border-ternary-dark">
                                <h5 className=" text-primary-dark dark:text-primary-light text-xl">
                                    Que tipo de proyecto estas buscando?
                                </h5>
                                <button
                                    onClick={onClose}
                                    className="px-4 font-bold text-primary-dark dark:text-primary-light"
                                >
                                    <FiX className="text-3xl"/>
                                </button>
                            </div>
                            <div className="modal-body p-5 w-full h-full">
                                <Formik
                                    initialValues={{
                                        name: '',
                                        email: '',
                                        subject: '',
                                        message: ''
                                    }}

                                    validate={(values) => {
                                        let errors = {};

                                        if (!values.name) {
                                            errors.name = 'Por favor ingresa un nombre'
                                        } else if (!/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(values.name)) {
                                            errors.name = 'El nombre solo puede contener letras y espacios'
                                        }
                                        if (!values.email) {
                                            errors.email = 'Por favor ingresa un email'
                                        } else if (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(values.email)) {
                                            errors.email = 'El correo solo puede contener letras, numeros, puntos, guiones y' +
                                                ' guion bajo'
                                        }
                                        if (!values.subject) {
                                            errors.subject = 'Por favor elegi un tipo de app'
                                        }
                                        if (values.message.length > 2000) {
                                            errors.message = 'Como maximo 2000 caracteres'
                                        }


                                        return errors;
                                    }}

                                    onSubmit={async (e, {resetForm}) => {
                                        resetForm();
                                        console.log(e)

                                        await sendEmail(e)
                                    }}
                                    className="max-w-xl m-4 text-left"
                                >
                                    {({errors}) => (
                                        <Form>
                                            <div className="">
                                                <Field
                                                    className="w-full px-5 py-2 border dark:border-secondary-dark rounded-md
                                            text-md bg-secondary-light dark:bg-ternary-dark text-primary-dark
                                            dark:text-ternary-light"
                                                    id="name"
                                                    name="name"
                                                    type="text"
                                                    required=""
                                                    placeholder="Nombre"
                                                    aria-label="Name"
                                                />
                                                <ErrorMessage name={'name'} component={() => (
                                                    <div
                                                        className={'error mt-4 mx-3 dark:border-secondary-dark rounded-md ' +
                                                            'text-md text-primary-dark dark:text-ternary-light'}
                                                    >{errors.name}</div>)}/>
                                            </div>
                                            <div className="mt-6">
                                                <Field
                                                    className="w-full px-5 py-2 border dark:border-secondary-dark rounded-md
                                            text-md bg-secondary-light dark:bg-ternary-dark text-primary-dark
                                            dark:text-ternary-light"
                                                    id="email"
                                                    name="email"
                                                    type="text"
                                                    required=""
                                                    placeholder="Email"
                                                    aria-label="Email"
                                                />
                                                <ErrorMessage name={'email'} component={() => (
                                                    <div
                                                        className={'error mt-4 mx-3 dark:border-secondary-dark rounded-md ' +
                                                            'text-md text-primary-dark dark:text-ternary-light'}>{errors.email}
                                                    </div>)}/>
                                            </div>
                                            <div className="mt-6">
                                                <Field
                                                    className="w-full px-5 py-2 border dark:border-secondary-dark rounded-md
                                                 text-md bg-secondary-light dark:bg-ternary-dark text-primary-dark
                                                 dark:text-ternary-light"
                                                    id="subject"
                                                    name="subject"
                                                    type="text"
                                                    as={'select'}
                                                    required=""
                                                    aria-label="Project Category"
                                                >
                                                    {selectOptions.map((option) => (
                                                        <option
                                                            className="text-normal sm:text-md"
                                                            key={option}
                                                        >
                                                            {option}
                                                        </option>
                                                    ))}
                                                </Field>
                                                <ErrorMessage name={'subject'} component={() => (
                                                    <div className={'error mt-4 mx-3'}>{errors.subject}</div>)}/>
                                            </div>

                                            <div className="mt-6">
                                                <Field
                                                    className="w-full px-5 py-2 border dark:border-secondary-dark rounded-md
                                            text-md
                                         bg-secondary-light dark:bg-ternary-dark text-primary-dark
                                         dark:text-ternary-light"
                                                    id="message"
                                                    name="message"
                                                    as={'textarea'}
                                                    cols="14"
                                                    rows="6"
                                                    aria-label="Details"
                                                    placeholder="Descripcion del proyecto"
                                                ></Field>

                                                <ErrorMessage name={'message'} component={() => (
                                                    <div className={'error mx-3'}>{errors.message}</div>)}/>

                                            </div>

                                            <div className="mt-6 pb-4 sm:pb-1">
									<span
                                        // onClick={onClose}
                                        type="submit"
                                        className="px-4
											sm:px-6
											py-2
											sm:py-2.5
											text-white
											bg-indigo-500
											hover:bg-indigo-600
											rounded-md
											focus:ring-1 focus:ring-indigo-900 duration-500"
                                        aria-label="Submit Request"
                                    >
										<Button type={'submit'} title="Enviar"/>
									</span>
                                            </div>

                                            {state.status === 200 && <div
                                                className="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg
                                            dark:bg-green-200 dark:text-green-800
                                            dark:border-secondary-dark rounded-md text-md text-primary-dark
                                            dark:text-ternary-light"
                                                role="alert">
                                                <span className="font-medium">Mensaje enviado con exito!</span>
                                            </div>}

                                        </Form>
                                    )}
                                </Formik>
                            </div>
                            <div className="modal-footer mt-2 sm:mt-0 py-5 px-8 border0-t text-right">
							<span
                                onClick={onClose}
                                type="button"
                                className="px-4
									sm:px-6
									py-2 bg-gray-600 text-primary-light hover:bg-ternary-dark dark:bg-gray-200
									dark:text-secondary-dark dark:hover:bg-primary-light
									rounded-md
									focus:ring-1 focus:ring-indigo-900 duration-500"
                                aria-label="Close Modal"
                            >
								<Button title="Cerrar"/>
							</span>
                            </div>
                        </div>
                    </div>
                </main>
            </motion.div>
        </>;
        return div;
    }
;

export default HireMeModal;
