import {useContext, useEffect} from 'react';
import SingleProjectContext from '../../context/SingleProjectContext';
import {useNavigate, useParams} from "react-router-dom";
import {ProjectsContext} from "../../context/ProjectsContext";
import {projectsData} from "../../data/projects";

const ProjectInfo = () => {
    // const { singleProjectData } = useContext(SingleProjectContext);
    const params = useParams()
    const navigate = useNavigate()
    const singleProjectData = projectsData
    console.log(projectsData[params.id-1])


    useEffect(() => {


    }, [params]);


    return (
        <div className="block sm:flex gap-0 sm:gap-10 mt-14">

            <div className="w-full sm:w-1/3 text-left">
                {/* Single project client details */}
                <div className="mb-7">
                    <p className="font-general-regular text-2xl font-semibold text-secondary-dark dark:text-secondary-light mb-2">
                        { params.id < 1 || params.id > 2 || Number.isNaN(Number(params.id)) ? navigate('/') : singleProjectData[params.id - 1].ProjectInfo.ClientHeading}
                    </p>
                    <ul className="leading-loose">
                        { params.id < 1 || params.id > 2 || Number.isNaN(Number(params.id)) ? navigate('/') : singleProjectData[params.id - 1].ProjectInfo.CompanyInfo.map(
                            (info) => {
                                return (
                                    <li
                                        className="font-general-regular text-ternary-dark
										dark:text-ternary-light"
                                        key={info.id}
                                    >
                                        <span>{info.title}: </span>
                                        <a
                                            href={info.details}
                                            target={'_blank'}
                                            className={
                                                info.title === 'Website' ||
                                                info.title === 'Phone'
                                                    ? 'hover:underline hover:text-indigo-500 dark:hover:text-indigo-400 cursor-pointer duration-300'
                                                    : ''
                                            }
                                            aria-label="Project Website and Phone"
                                        >
                                            {info.details}
                                        </a>
                                    </li>
                                );
                            }
                        )}
                    </ul>
                </div>

                {/* Single project objectives */}
                <div className="mb-7">
                    <p className="font-general-regular text-2xl font-semibold text-ternary-dark dark:text-ternary-light mb-2">
                        { params.id < 1 || params.id > 2 || Number.isNaN(Number(params.id)) ? navigate('/') : singleProjectData[params.id - 1].ProjectInfo.ObjectivesHeading}
                    </p>
                    <p className="font-general-regular text-primary-dark dark:text-ternary-light">
                        { params.id < 1 || params.id > 2 || Number.isNaN(Number(params.id)) ? navigate('/') : singleProjectData[params.id - 1].ProjectInfo.ObjectivesDetails}
                    </p>
                </div>
                {/* Deploy */}
                <div className="mb-7">
                    <p className="font-general-regular text-2xl font-semibold text-ternary-dark dark:text-ternary-light mb-2">
                        { params.id < 1 || params.id > 2 || Number.isNaN(Number(params.id)) ? navigate('/') : singleProjectData[params.id - 1].ProjectInfo.Deploy[0].title}
                    </p>
                    <a
                        href={ params.id < 1 || params.id > 2 || Number.isNaN(Number(params.id)) ? navigate('/') : singleProjectData[params.id - 1].ProjectInfo.Deploy[0].details}
                        target={'_blank'}
                        className={
                            'hover:underline hover:text-indigo-500 dark:hover:text-indigo-400 ' +
                            'cursor-pointer duration-300' +
                            'font-general-regular text-ternary-dark\n' +
                            '\t\t\t\t\t\t\t\t\t\tdark:text-ternary-light'
                        }
                        aria-label="Project Website and Phone"
                    >
                        { params.id < 1 || params.id > 2 || Number.isNaN(Number(params.id)) ? navigate('/') : singleProjectData[params.id - 1].ProjectInfo.Deploy[0].details}
                    </a>
                </div>

                {/* Single project technologies */}
                <div className="mb-7">
                    <p className="font-general-regular text-2xl font-semibold text-ternary-dark dark:text-ternary-light mb-2">
                        { params.id < 1 || params.id > 2 || Number.isNaN(Number(params.id)) ? navigate('/') : singleProjectData[params.id - 1].ProjectInfo.Technologies[0].title}
                    </p>
                    <p className="font-general-regular text-primary-dark dark:text-ternary-light">
                        { params.id < 1 || params.id > 2 || Number.isNaN(Number(params.id)) ? navigate('/') : singleProjectData[params.id - 1].ProjectInfo.Technologies[0].techs.join(
                            ', '
                        )}
                    </p>
                </div>

                {/* Single project social sharing */}
                <div>
                    <p className="font-general-regular text-2xl font-semibold text-ternary-dark dark:text-ternary-light mb-2">
                        { params.id < 1 || params.id > 2 || Number.isNaN(Number(params.id)) ? navigate('/') : singleProjectData[params.id - 1].ProjectInfo.SocialSharingHeading}
                    </p>
                    <div className="flex items-center gap-3 mt-5">
                        { params.id < 1 || params.id > 2 || Number.isNaN(Number(params.id)) ? navigate('/') : singleProjectData[params.id - 1].ProjectInfo.SocialSharing.map(
                            (social) => {
                                return (
                                    <a
                                        key={social.id}
                                        href={social.url}
                                        target="__blank"
                                        aria-label="Share Project"
                                        className="bg-ternary-light dark:bg-ternary-dark text-gray-400 hover:text-primary-dark dark:hover:text-primary-light p-2 rounded-lg shadow-sm duration-500"
                                    >
										<span className="text-lg lg:text-2xl">
											{social.icon}
										</span>
                                    </a>
                                );
                            }
                        )}
                    </div>
                </div>
            </div>

            {/*  Single project right section */}
            <div className="w-full sm:w-2/3 text-left mt-10 sm:mt-0">
                <p className="font-general-regular text-primary-dark dark:text-primary-light text-2xl font-bold mb-7">
                    { params.id < 1 || params.id > 2 || Number.isNaN(Number(params.id)) ? navigate('/') : singleProjectData[params.id - 1].ProjectInfo.ProjectDetailsHeading}
                </p>
                { params.id < 1 || params.id > 2 || Number.isNaN(Number(params.id)) ? navigate('/') : singleProjectData[params.id - 1].ProjectInfo.ProjectDetails.map((details) => {
                    return (
                        <p
                            key={details.id}
                            className="font-general-regular mb-5 text-lg text-ternary-dark dark:text-ternary-light"
                        >
                            {details.details}
                        </p>
                    );
                })}
            </div>
        </div>
    );
};

export default ProjectInfo;
